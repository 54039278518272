import React, { useEffect } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import GoTo from "../components/goTo"
import HeroImage from "../components/heroImage"

const IndexPage = ({ data }) => {
  const intl = useIntl()
  return (
    <React.Fragment>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "title" })} description={intl.formatMessage({ id: "index.caption" })}/>
      <Layout intl={intl}>
        <HeroImage
          title={intl.formatMessage({ id: "index.title" })}
          caption={intl.formatMessage({ id: "index.caption" })}
          image={data.heroImage}
        />

        <section className="container mx-auto px-5 py-10 my-20">
          <div className="-mx-5 flex flex-wrap">
            <div className="px-5 w-full lg:w-1/2 my-auto">
              <Img fluid={data.forest.childImageSharp.fluid} />
            </div>
            <div className="px-5 w-full lg:w-1/2">
              <h2 className="text-green-600 text-4xl font-light text-center lg:text-left">
                {intl.formatMessage({ id: "index.first_section_title" })}
              </h2>
              <p
                className="font-light text-gray-600 my-5"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "index.first_section_content",
                  }),
                }}
              ></p>
              <a
                href="https://www.springer.com/gp/book/9783030158385"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green-600 hover:text-green-800"
              >
                {intl.formatMessage({ id: "index.first_section_springer" })}
              </a>
            </div>
          </div>
        </section>

        <section className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.tentang}
              page="/about-us/"
              title={intl.formatMessage({ id: "about.title" })}
              caption={intl.formatMessage({ id: "about.caption" })}
              button={intl.formatMessage({ id: "about.button" })}
            />
          </div>
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.expertise}
              page="/expertise/"
              title={intl.formatMessage({ id: "expertise.title" })}
              caption={intl.formatMessage({ id: "expertise.caption" })}
              button={intl.formatMessage({ id: "expertise.button" })}
            />
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "index/alastair-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    forest: file(relativePath: { eq: "index/forest-management.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tentang: file(relativePath: { eq: "about/tentang-yayasan.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    expertise: file(relativePath: { eq: "expertise/kepakaran.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
